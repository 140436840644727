import React from 'react';
import { connect } from 'react-redux';

import CashPageHome         from 'app/cash/components/page-home';
import PageLogin            from 'app/components/auth/page-login';
import PageSignup           from 'app/components/auth/page-signup';
import PageError            from 'app/components/errors/page';
import PageNoIe             from 'app/components/errors/page-no-ie';
import PageHome             from 'app/components/page-home';
import { Pages }            from 'app/constants';
import SnkHome              from 'app/dotsnake/components/home/page';
import SnkLeaderboard       from 'app/dotsnake/components/leaderboard/page';
import SnkEmailVerification from 'app/dotsnake/components/page-email-verification';
import SnkInstructions      from 'app/dotsnake/components/page-instructions';
import SnkLanding           from 'app/dotsnake/components/page-landing';
import SnkSettings          from 'app/dotsnake/components/settings/page';
import SnkPlay              from 'app/dotsnake/components/play/page';
import RoutingDuck          from 'app/ducks/routing';
import TybroHome            from 'app/tybro/components/page-home';
import TylerHome            from 'app/tyler/components/page-home';

const pageComponentMap = {
  [Pages.NOT_FOUND]: PageError[404],
  [Pages.FORBIDDEN]: PageError[403],
  [Pages.HOME]: PageHome,
  [Pages.LOGIN]: PageLogin,
  [Pages.SIGN_UP]: PageSignup,

  [Pages.TYBRO_HOME]: TybroHome,

  [Pages.TYLER_HOME]: TylerHome,

  [Pages.SNK_EMAIL_VERIFICATION]: SnkEmailVerification,
  [Pages.SNK_HOME]: SnkHome,
  [Pages.SNK_LANDING]: SnkLanding,
  [Pages.SNK_INSTRUCTIONS]: SnkInstructions,
  [Pages.SNK_PLAY]: SnkPlay,
  [Pages.SNK_SCORES]: SnkLeaderboard,
  [Pages.SNK_SETTINGS]: SnkSettings,

  [Pages.CASH_HOME]: CashPageHome,
};

// these pages will use key={path}
// handy for forcing paths like /users/:id to remount the page component when the id changes
const keyOnPath = [
];

class CurrentPage extends React.PureComponent {

  render() {
    const { page, path } = this.props;
    const shouldKeyOnPath = keyOnPath.includes(page);
    const props = shouldKeyOnPath ? {key: path} : {};
    const Component = pageComponentMap[page] || PageError[404];
    return <Component {...props} />;
  }

}

const stateToProps = (state) => ({
  page: RoutingDuck.Slx.page(state),
  path: RoutingDuck.Slx.path(state),
});

export default connect(stateToProps)(CurrentPage);
