import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import { connect } from 'react-redux';

import Api           from 'app/apis/main';
import Apps          from 'app/apps';
import CheckBox      from 'app/components/common/checkbox';
import Link          from 'app/components/common/link';
import StandardInput from 'app/components/common/standard-input';
import RoutingDuck   from 'app/ducks/routing';
import history       from 'app/history';
import paths         from 'app/paths';

const cacheKey = 'cache-cash';

const formatUsd = (cents, showCents=true) => {
  const fmtStr = showCents ? '$0,0.00' : '$0,0';
  return numeral(cents / 100).format(fmtStr);
};

const formatHours = (minutes, singleDec=false) => {
  const formatStr = singleDec ? '0.0' : '0.00';
  const hours = minutes / 60;
  return numeral(hours).format(formatStr);
};

class PageCashHome extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      data: null,
      loadError: false,
      needsSignIn: false,
      password: '',
    };
  }

  get storage() {
    return Apps.getApp().storage;
  }

  get dates() {
    const firstDate = moment(this.params.startDate).startOf('isoWeek').format('YYYY-MM-DD');
    const dates = [];
    const lastDate = moment(this.params.endDate).endOf('isoWeek').format('YYYY-MM-DD');
    // const lastDate = moment().endOf('year').endOf('isoWeek').format('YYYY-MM-DD');
    let dateStr = lastDate;
    while (dateStr >= firstDate) {
      dates.push(dateStr);
      dateStr = moment.utc(dateStr).subtract(1, 'day').format('YYYY-MM-DD');
    }
    return dates;
  }

  get months() {
    const months = [];
    let ia = null;
    let iy = null;
    let iq = null;
    let im = null;
    let month = null;
    _.chunk(this.dates, 7).forEach((weekDates, i) => {
      // const date = _.last(weekDates);
      const date = weekDates[0];
      const mom = moment.utc(date);
      const y = mom.format('YYYY');
      const q = mom.format('YYYY-Q');
      const m = mom.format('YYYY-MM');
      const newAll     = ia === null;
      const newYear    = iy !== y;
      const newQuarter = iq !== q;
      const newMonth   = im !== m;
      if (newMonth) {
        im = m;
        month = {month: date, weeks: []};
        months.push(month);
      };
      if (newQuarter) {
        iq = q;
        month.quarter = date;
      };
      if (newYear) {
        iy = y;
        month.year = date;
      };
      if (newAll) {
        ia = true;
        month.all = true;
      };
      month.weeks.push([...weekDates].reverse());
    });
    const first = months[0];
    const stripFirst = (() => {
      if (first.weeks.length > 1) return false;
      const firstDate = first.weeks[0][0];
      if (firstDate <= this.params.endDate) return false;
      return true;
    })();
    // if (stripFirst) months.shift();
    return months;
  }

  get todayDate() {
    return moment().format('YYYY-MM-DD');
  }

  get params() {
    const {query} = this.props;
    const p = {
      week: true,
      month: true,
      quarter: false,
      year: false,
      startDate: '2024-11-10',
      endDate: moment().format('YYYY-MM-DD'),
      tab: 'time',
    };
    ['week', 'month', 'quarter', 'year'].forEach((name) => {
      if (query[name] === 'true') p[name] = true;
      if (query[name] === 'false') p[name] = false;
    });
    if (query.tab === 'time' || query.tab === 'invoice') {
      p.tab = query.tab;
    }
    if ((query.startDate || '').match(/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/)) {
      if (moment(query.startDate).isValid()) p.startDate = query.startDate;
    }
    if ((query.endDate   || '').match(/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/)) {
      if (moment(query.endDate).isValid()) p.endDate = query.endDate;
    }
    return p;
  }

  get monthNest() {
    let nest = 0;
    if (this.params.quarter) nest += 1;
    if (this.params.year) nest += 1;
    return nest;
  }

  get quarterNest() {
    let nest = 0;
    if (this.params.year) nest += 1;
    return nest;
  }

  async componentDidMount() {
    window.comp = this;
    const cachedData = await this.storage.getObj(cacheKey);
    if (cachedData) {
      this.setState({data: cachedData});
      console.log('USING CACHED DATA');
      return;
    }
    this.fetch();
  }

  async fetch() {
    try {
      const fetchedData = await Api.harvestLoadAll();
      this.setState({data: fetchedData});
      this.storage.setObj(cacheKey, fetchedData);
    } catch (error) {
      const is401 = error.response?.status === 401;
      const newState = is401 ? {needsSignIn: true} : {loadError: true};
      this.setState(newState);
      if (!is401) {
        throw error;
      }
    }
  }

  onClickSignIn() {
    Api.harvestSignIn(this.state.password || '')
      .then(() => {
        window.location.href = window.location.href;
      })
      .catch((error) => {
        alert('sign in failed');
      });
  }

  onToggleParam(name, nullWhen, event) {
    const checked = event.target.checked;
    const val = (nullWhen === checked) ? null: checked;
    this.setQuery({[name]: val});
  }

  setQuery(newQuery) {
    const query = {...this.props.query, ...newQuery};
    const path = paths.cshHome(query);
    history.push(path);
  }

  async onClickSignOut() {
    await this.storage.clear(cacheKey);
    await Api.harvestSignOut();
    alert('done');
  }

  async onClickClearCache() {
    await this.storage.clear(cacheKey);
    this.fetch();
  }

  onChangePw(event) {
    this.setState({password: event.target.value});
  }

  getTimeData(startDate, endDate) {
    const entries = (this.state.data?.timeEntries || []).filter(entry => (entry.date >= startDate) && (entry.date <= endDate));
    const minutes = _.sumBy(entries, 'minutes');
    const hours = minutes / 60;
    const cents = hours * 12500;
    const dollars = cents / 100;
    const count = entries.length;
    return {minutes, hours, cents, dollars, count};
  }

  renderAll(month) {
    if (!month.all) return null;
    const {minutes, cents} = this.getTimeData('0000-00-00', '9999-12-31');
    return (<>
      <div className="csh-period all">
        <div className="csh-period-title">All Time</div>
        <div className="csh-period-line"></div>
        <div className="csh-period-stats">
          <div className="csh-period-time">{formatHours(minutes, true)}<span className="csh-period-label">hrs</span></div>
          <div className="csh-period-cash">{formatUsd(cents)}</div>
        </div>
      </div>
    </>);
  }

  renderYear(month) {
    if (!month.year) return null;
    if (!this.params.year) return null;
    const mom = moment(month.year);
    const year = mom.format('YYYY');
    const firstDate = mom.clone().startOf('year').format('YYYY-MM-DD');
    const lastDate  = mom.clone().endOf('year'  ).format('YYYY-MM-DD');
    const {minutes, cents} = this.getTimeData(firstDate, lastDate);
    return (<>
      <div className="csh-period year">
        <div className="csh-period-title">{year}</div>
        <div className="csh-period-line"></div>
        <div className="csh-period-stats">
          <div className="csh-period-time">{formatHours(minutes, true)}<span className="csh-period-label">hrs</span></div>
          <div className="csh-period-cash">{formatUsd(cents)}</div>
        </div>
      </div>
    </>);
  }

  renderQuarter(month) {
    if (!month.quarter) return null;
    if (!this.params.quarter) return null;
    const mom = moment(month.quarter);
    const qNum = mom.format('Q');
    const year = mom.format('YYYY');
    const firstDate = mom.clone().startOf('quarter').format('YYYY-MM-DD');
    const lastDate  = mom.clone().endOf('quarter'  ).format('YYYY-MM-DD');
    const {minutes, cents} = this.getTimeData(firstDate, lastDate);
    return (<>
      <div className={`csh-period nest-${this.quarterNest}`}>
        <div className="csh-period-title">{`Q${qNum}`} <span className="csh-period-label">{year}</span></div>
        <div className="csh-period-line"></div>
        <div className="csh-period-stats">
          <div className="csh-period-time">{formatHours(minutes, true)}<span className="csh-period-label">hrs</span></div>
          <div className="csh-period-cash">{formatUsd(cents)}</div>
        </div>
      </div>
    </>);
  }

  renderMonth(month) {
    if (!month.month) return null;
    if (!this.params.month) return null;
    const mom = moment(month.month);
    const monthName = mom.format('MMMM');
    const year = mom.format('YYYY');
    const firstDate = mom.clone().startOf('month').format('YYYY-MM-DD');
    const lastDate  = mom.clone().endOf('month'  ).format('YYYY-MM-DD');
    const {minutes, cents} = this.getTimeData(firstDate, lastDate);
    return (<>
      <div className={`csh-period nest-${this.monthNest}`}>
        <div className="csh-period-title">{monthName} <span className="csh-period-label">{year}</span></div>
        <div className="csh-period-line"></div>
        <div className="csh-period-stats">
          <div className="csh-period-time">{formatHours(minutes, true)}<span className="csh-period-label">hrs</span></div>
          <div className="csh-period-cash">{formatUsd(cents)}</div>
        </div>
      </div>
    </>);
  }

  renderCal(month) {
    if (!this.params.week) return null;
    const curMonthName = moment(month.month).format('MMM');
    return (
      <div className="csh-cal-con">
        <div className="csh-cal">
          <div className="csh-weekhead">
            <div className="csh-weekhead-box">Mon</div>
            <div className="csh-weekhead-box">Tue</div>
            <div className="csh-weekhead-box">Wed</div>
            <div className="csh-weekhead-box">Thu</div>
            <div className="csh-weekhead-box">Fri</div>
            <div className="csh-weekhead-box">Sat</div>
            <div className="csh-weekhead-box">Sun</div>
            <div className="csh-weekhead-box">Week</div>
          </div>
          {month.weeks.map((dates, i) => {
            const firstDate = dates[0];
            const lastDate = _.last(dates);
            const {minutes: wMinutes, cents: wCents} = this.getTimeData(firstDate, lastDate);
            return (
              <div key={i} className="csh-week">
                {dates.map((date) => {
                  const dayOfMonth = date.split('-')[2];
                  const isToday = date === this.todayDate;
                  const {minutes, cents} = this.getTimeData(date, date);
                  const monthName = moment(date).format('MMM');
                  const isCurMonth = monthName === curMonthName;
                  return (
                    <div key={date} className={`csh-week-day ${isToday ? 'today' : ''} ${isCurMonth ? '' : 'dif-month'}`}>
                      <div className="csh-week-day-date">{`${isCurMonth ? '' : monthName}${dayOfMonth}`}</div>
                      <div className="csh-week-day-time">{minutes ? formatHours(minutes) : ''}</div>
                      {/* <div className="csh-week-day-cash">{cents ? formatUsd(cents, false) : ''}</div> */}
                    </div>
                  );
                })}
                <div className="csh-week-sum">
                  <div className="csh-week-sum-time">{wMinutes ? formatHours(wMinutes) : ''}</div>
                  <div className="csh-week-sum-cash">{wCents ? formatUsd(wCents, false) : ''}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  renderTabTime() {
    const {data} = this.state;
    if (this.params.tab !== 'time') return null;
    return (
      <div className="csh-tab-time">
        <div className="csh-filters">
          <div className="csh-filter">
            <CheckBox id="cb-week" className="csh-checkbox" checked={this.params.week} onChange={this.onToggleParam.bind(this, 'week', true)} isToggle offOk />
            <label htmlFor="cb-week">Weeks</label>
          </div>
          <div className="csh-filter">
            <CheckBox id="cb-month" className="csh-checkbox" checked={this.params.month} onChange={this.onToggleParam.bind(this, 'month', true)} isToggle offOk />
            <label htmlFor="cb-month">Months</label>
          </div>
          <div className="csh-filter">
            <CheckBox id="cb-quarter" className="csh-checkbox" checked={this.params.quarter} onChange={this.onToggleParam.bind(this, 'quarter', false)} isToggle offOk />
            <label htmlFor="cb-quarter">Quarters</label>
          </div>
          <div className="csh-filter">
            <CheckBox id="cb-year" className="csh-checkbox" checked={this.params.year} onChange={this.onToggleParam.bind(this, 'year', false)} isToggle offOk />
            <label htmlFor="cb-year">Years</label>
          </div>
        </div>
        <div>
          {this.months.map((month, i) => {
            return (<React.Fragment key={i}>
              {this.renderAll(month)}
              {this.renderYear(month)}
              {this.renderQuarter(month)}
              {this.renderMonth(month)}
              {this.renderCal(month)}
            </React.Fragment>);
          })}
        </div>
      </div>
    );
  }

  renderTabInvoice() {
    const {data} = this.state;
    if (this.params.tab !== 'invoice') return null;
    return (
      <div className="csh-tab-inv">
        <h2>Invoices</h2>
      </div>
    );
  }

  renderData() {
    const {data} = this.state;
    if (!data) return null;
    return (<>
      {this.renderTabTime()}
      {this.renderTabInvoice()}
    </>);
  }

  renderSignIn() {
    const {needsSignIn} = this.state;
    if (!needsSignIn) return null;
    return (
      <div className="csh-login">
        <h1 className="csh-login-title">Login</h1>
        <input className="csh-login-pw" type="password" name="password" value={this.state.password || ''} onChange={this.onChangePw.bind(this)} placeholder="Password" />
        <button className="csh-btn csh-login-btn" onClick={this.onClickSignIn.bind(this)}>Sign In</button>
      </div>
    );
  }

  render() {
    const {query} = this.props;
    const {loadError, data} = this.state;
    if (loadError) return <p>Oops! Something went wrong.</p>;
    const {tab} = this.params;
    const refreshLabel = (() => {
      if (!data?.fetchedAt) return null;
      const dateStr = moment.unix(data.fetchedAt / 1000).format('MMM D, hh:mmA');
      return `Last Fetch: ${dateStr}`;
    })();

    return (
      <div className="csh">
        <div className="csh-head">
          <div className="csh-widther">
            <h1 className="csh-head-title">Cash</h1>
            <div className="csh-head-tabs">
              <Link href={paths.cshHome({...this.props.query, tab: null})} className={`${tab === 'time' ? 'active' : ''}`}>Time</Link>
              <Link href={paths.cshHome({...this.props.query, tab: 'invoice'})} className={`${tab === 'invoice' ? 'active' : ''}`}>Invoices</Link>
            </div>
            <div className="csh-head-controls">
              {refreshLabel && <div className="csh-head-controls-ts">{refreshLabel}</div>}
              <div className="csh-head-controls-btns">
                <button className="csh-btn" onClick={this.onClickClearCache.bind(this)}>Refresh</button>
                <button className="csh-btn" onClick={this.onClickSignOut.bind(this)}>Sign Out</button>
              </div>
            </div>
          </div>
        </div>
        {/* <pre>{JSON.stringify({query, params: this.params},null,4)}</pre> */}
        {/* <pre>{JSON.stringify(_.omit(this.state, ['data']),null,4)}</pre> */}

        <div className="csh-widther">
          {this.renderSignIn()}
          {this.renderData()}
        </div>

        <br /><br /><br /><br /><br /><br />
      </div>
    );
  }

}

const stateToProps = (state) => ({
  query: RoutingDuck.Slx.query(state),
});
const dispatchToProps = (dispatch) => ({

});

export default connect(stateToProps, dispatchToProps)(PageCashHome);
